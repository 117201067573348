.plus-button {
  width: 34px;
  cursor: pointer;
  transition: 500ms;
  opacity: 0.5;
}

.plus-button:hover {
  opacity: 1;
}

.modal-hide-div {
  background-color: rgb(0, 0, 0);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.5;
  z-index: 100;
}

.modal-show {
  z-index: 101 !important;
  opacity: 1;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-down {
  animation-name: fadeInDown;
  animation-duration: 500ms;
  animation-timing-function: ease-in-out;
}

.warning-modal-container {
  width: 48rem;
  background-color: white;
  z-index: 101;
  position: fixed;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  border-radius: 10px;
  transition: 500ms all;
}

.doc-modal-header {
  height: 60px;
}

.doc-modal-header h2 {
  padding-left: 20px;
}

.buttons-line {
  margin-top: 10px;
  width: 580px;
}

.uploaded-files {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.uploaded-file {
  width: 89px;
  height: 100px;
  background-color: rgb(149 194 255 / 51%);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #507ab4;
  border: solid 1px #7cb3ff;
}

.doc-size {
  font-weight: 800;
}

.file-name {
  font-size: 10px;
  text-align: center;
}

.cancel-doc {
  position: absolute;

  color: black;
  background-color: white;
  margin-top: -95px;
  margin-right: -95px;
  border-radius: 50px;
  z-index: 9999;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
}
.drag-and-drop {
  z-index: 1;
}
.extra-height {
  height: 330px !important;
}

.file-selection {
  cursor: pointer;
}

.docs-svg {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.grey-button {
  background-color: #0000000f !important;
  color: #5c5e6e;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 500ms !important;
  border-radius: 10px !important;
  cursor: pointer;
}

.grey-button:hover {
  background-color: #00000018 !important;
}

.delete-button {
  background-color: rgba(255, 0, 0, 0.11);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: 500ms all;
  cursor: pointer;

  border-radius: 10px !important;
}

.delete-button:hover {
  background-color: rgba(255, 0, 0, 0.171);
}

.buttons-docs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 110px;
}

.see-button {
  background-color: #f1faff !important;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}



.warning-div {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
}


.buttons-delete-line {
  margin-top: 20px;
  color: #008000;
}
