.modal-body {
  border-radius: 10px;
}

.welcome-logo {
  width: 400px !important;
  margin-bottom: 20px;
}

.azeaze {
  color: white !important;
  background-color: red;
}

.pw-input-container {
  height: 60px;
  display: flex;

  gap: 20px;
  align-items: center;
}

.pw-modal {
  /* height: 320px !important; */
  width: 790px !important;
}

.pw-modal-resolutions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 20px;
  padding-top: 20px;
}
.pw-modal-form {
  width: 560px;
}

.error-div {
  color: red;
  position: absolute;
  bottom: 35px;
}

.pw-input {
  border: solid red 1px !important;
}

.updated-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
}

.resource-updated {
  background-color: rgba(0, 128, 0, 0.103);
  width: 135px;
  height: 35px;
  border: 1px dotted green;
  display: flex;
  justify-content: center;
  align-items: center;
  color: green;
  font-size: 13px;
  border-radius: 10px;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.resource-update-failed {
  background-color: rgba(255, 0, 0, 0.103);
  width: 125px;
  height: 35px;
  border: 1px dotted rgb(255, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 0, 0);
  font-size: 13px;
  border-radius: 10px;
}

.svg-done {
  height: 150px;
  position: absolute;
  left: 38%;
  top: 35%;
}

.drag-and-drop {
  padding-top: 10px;
  padding-bottom: 2px;
  display: flex;
  justify-content: center;
}

.double-auth-options {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
}

.flex-form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  /* padding-top: 20px; */
}

.heigh-300-imp {
  height: 300px !important;
}

.form-control-error {
  display: block;
  width: 100%;
  padding: 0.775rem 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #f1416c!important;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #f1416c!important;
  appearance: none;
  border-radius: 0.475rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.amount-input {
  border: solid #d7d7d7 1px;
  border-radius: 4px;
  height: 2.5rem;
  width: 100% !important;
  padding: 4px 10px;
  pointer-events: none;
}

.ref-input {
  border: solid #d7d7d7 1px;
  border-radius: 4px;
  height: 2.5rem;
  width: 100% !important;
  padding: 4px 10px;
}



.svg-menu-currency {
  height: 14px !important;
  position: absolute !important;
  right: 8px !important;
  top: 8px !important;
}

.line-divs-container {
  display: flex;
  gap: 10px;
}

.w-60 {
  width: 60%;
}
.w-70 {
  width: 70%;
}

.w-63{
  width: 63%;
}
.w-40 {
  width: 40%;
}



.upload-receipt > svg {
  height: 20px;
}

.w-50 {
  width: 50%;
}

.items-div {
  width: 100%;
  height: 2.5rem;
  border: solid #d7d7d7 1px;
  border-radius: 4px;
  cursor: pointer;
}

.items-div > span {
  position: absolute;
  top: 5.9px;
  left: 15px;
  font-weight: 600;
  color: rgb(114, 114, 114);
}

.items-div > div {
  min-height: 35px;
  width: 100%;
  position: absolute;
  top: 30px;
  left: 0;
  border-radius: 4px;
  border: solid #d7d7d7 1px;
  display: flex;
  flex-direction: column;
  background-color: rgb(250, 254, 255);
  z-index: 1;
  max-height: 200px !important;
  overflow-y: scroll;
}

.items-div > div > span {
  background-color: #f9fdff;
  transition: 300ms all;
  margin: 2px 4px;
  height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  font-weight: 600;
  color: rgb(114, 114, 114);
}

.items-div > div > span:hover {
  background-color: #e8f7ff;
}




