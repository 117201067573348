.feature-desc{
    font-weight: 500;
    display: inline-block;
    background-color: #f5f8fa;
    color: #7e8299;
    padding: 4px 10px;
    border-radius: 4px;
}

.bg-light-footer{
    background-color: #f5f8fa52 !important;
    color: grey !important;
    font-weight: 500 !important;
    font-style: italic;
}

.old-price{
    color: red;
    text-decoration: line-through;
    font-size: 11px!important;
}

.not-supported{
    color: grey;
}

.free-price{
    color: #3aa369;
}