#kt_project_users_table_pane * {
  font-size: 12px;
  font-size: 700;
}

#kt_project_users_table_pane * {
  border: none !important;
}

#kt_project_users_table_pane .sc-gEvEer {
  border-bottom: 1px solid rgba(199, 199, 199, 0.301) !important ;
}

#kt_project_users_table_pane .text-gray-800 {
  font-weight: 700;
}

#kt_project_users_table_pane .sc-cwHptR {
  font-weight: 700;
  color: rgb(170, 170, 170);
}

#kt_project_users_table_pane .menu-gray-600 div a {
  font-weight: 600 !important;
}

#kt_project_users_table_pane .sc-dhKdcB div {
  font-weight: 700;
}

#kt_project_users_table_pane .sc-dhKdcB {
  padding: 18px 0;
  border-bottom: 1px solid rgba(199, 199, 199, 0.301) !important ;
}

.img-soon-button {
  position: absolute;
  width: 50px;
  margin-top: 31px;
  margin-left: -20px;
}

.dt-header-container-numbers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.features-card {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.features-dt {
  display: flex;
flex-wrap: wrap;
  justify-content: flex-start;
  gap: 5px;
}

.card-date {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title-dt-options {
  margin-top: 5px;
  margin-bottom: 5px;
}

.numbers-dt-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 50px;
  right: 28px;
  bottom: 33px;
  border-radius: 5px;
  padding-right: 20px;
}

.card-dim {
  height: 100%!important;
}

.dashed-div-dim {
  min-height: 60px !important;
}

.margin-bottom {
  margin-bottom: 2.5em;
}

.margin-dim {
  margin: 20px 0;
}

.rows-per-page {
  margin-right: 20px;
}

.rows-per-page-select {
  border: none;
  background-color: transparent;
}

.marg-left {
  margin-left: 20px;
}

.width-div-numbers {
  width: 160px !important;
  height: 100%;
}

.date-numbers {
  border: none;
  padding: 8px 10px 0 10px;
  border-radius: 5px;

  color: grey;
}

.rdt_TableCol_Sortable div {
  font-size: 12px;
  font-weight: 700;
  color: #aaaaaa;
}

.empty-numbers {
  position: absolute;
  top: 370px;
  left: 826px;
  z-index: 2;
  width: 263px!important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;

}

.menu-absolute {
  position: absolute;
  right: 15px;
  top: 60px;
}

.menu-numbers {
  position: absolute !important;
  top: 49px !important;
  right: 72px !important;
  transform: none !important;
}

.filter-title-position {
  position: absolute;
  font-size: 10px;
  font-weight: 900;
  color: #858585;
  top: -15px;
  left: 5px;
}

.card-flag {
  display: flex!important;
  align-items: center !important;
  justify-content: center;
  gap: 5px;
}

.card-flag * {
  display: flex!important;
  align-items: center !important;
  justify-content: center;

  height: 16px;
}

.not-allowed{
  cursor: not-allowed!important;
}

.width-active{
width: 256px!important;
}

.rdt_TableBody{
overflow: visible!important;
}

.sc-feUZmu{
  overflow: visible!important;
}

.heiht-100{
  height: 100%!important;
}


.display-flex-line{
display: flex!important;
gap: 3px!important;
}

.flex-center{
display: flex;
justify-content: center;
align-items: center;
}

.form-floating label{
font-size: 10px!important;
padding-top: 10px!important;
}

.form-select{
 
  height: 42px!important;
  }

  .label-float{
    position: absolute;
    top: 2px;
    left: 10px;
    font-size: 10px!important;
    color: #b5b7be;
  }



  .num-input{
    padding-top: 15px!important;
  }


  .colored-total{
    color: #507ab4;
  }



  .full-size{
    height: 100%;
    width: 100%;
  }

  .flex-col{
    flex-direction: column;
  }
  .flex-col-num{
    flex-direction: column;
  }


  .pad-30{
    padding: 50px;
  }

  .gap-5{
    gap: 5px;
  }

  .m-top-10{
    margin-top: 10px;
  }

  
  .flex-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  }

  .showing-line{
    display: flex;
    align-items: center;
    gap: 2px;
    font-size: 14px;
    color: #869ab5;
  }

  .delete-date-filter{
    width: 17px;
    background-color: #d4d9e1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    color: #7c96b9;
    cursor: pointer;
    transition: 500ms all;
    font-weight: 800;
    font-size: 15px;
  }

  .delete-date-filter:hover{
    background-color: #a7a8aa;
    color: #516d92;

  }

  .flex-date{
    display: flex
  }

  .illus-empty{
    width: 350px;
  }

  .empty-numbers-txt{
    font-size: 14px;
    font-weight: 600;
    color: #70707c;
  }

  .date-placeholder::placeholder{
    color: red!important;
  }

  .width-div-numbers .date-picker-btn .flatpickr-input{
    height: 100%;
  }