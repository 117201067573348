.flex-space-evenly {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 10px !important;
  padding: 8px 12px !important;
}

.filter-selected {
  border: solid 1px #507ab4 !important;
}

#filter-selected {
  border: solid 1px #507ab4 !important;
  border-radius: 5px !important;
}

.flex-features {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.br-6 {
  border-radius: 6px;
}

.pay-button {
  border-radius: 4px;
  display: flex;
  background-color: #606b9f !important;
  color: white !important;
  padding: 7px 12px !important;
  font-size: 14px;
  justify-content: space-between;
}

.payment-modal-notice {
  border-radius: 4px;
  padding: 1em;
}

.payment-modal-notice > span {
  font-weight: 500;
}

.payment-modal-notice > span > label:first-child {
  padding: 0 2px;
}

.payment-modal-notice > span > label {
  font-weight: 800;
}

.danger-notice {
  background-color: #ffe8e8;
  border: dashed 1px #ff9e9e;
  color: #954e4e;
}

.warning-notice {
  background-color: #fff3d4;
  border: 1px dashed #fbbb54;
  color: #ab6f10;
}

.success-notice {
  color: #149525 !important;
  background-color: hwb(125 69% 5% / 0.3);
  border: dashed 1px #85b38b;
}

.w-50em {
  width: 50em;
}

.w-60em {
  width: 60em;
}

.confirmation-checkbox:checked {
  background-color: #0075ff !important;
  border-color: #0075ff !important;
}
.confirmation-checkbox {
  border-radius: 0 !important;
  width: 1.3rem !important;
  height: 1.3rem !important;
}
.input-danger {
  border: #d74168 1px solid !important;
}
.insufisant-amount {
  bottom: -19px;
  left: 0;
  color: #d74168;
}

.h-2em {
  height: 2em !important;
}

.p-r-0 {
  padding-right: 0 !important;
}

.p-l-0 {
  padding-left: 0 !important;
}

.br-l-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.br-r-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.br-0 {
  border-radius: 0 !important;
}

.blue-alert {
  background-color: #eefbff;
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 0.5rem 1rem;
  gap: 1rem;
  border: #205d8424 dashed 1px;
  color: #4c93d5;
}
