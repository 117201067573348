.invoices-modal {
  height: auto !important;
  width: 90% !important;
  min-height: 19% !important;
}

.calls-sms-modal {
  height: auto !important;
  width: 92% !important;
  min-height: 27% !important;
}

.allocate-user-modal {
  width: 700px !important;
}

.dt-container {
  width: 100%;
  padding: 10px;
}

.dt-header-bg {
  background-color: #00000008;
  width: 97.2%;
  height: 4.2rem;
  z-index: 1;
  position: absolute;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.margin-top-3-per {
  margin-top: 3%;
}

.pad-r-l {
  padding: 0 20px !important;
}

.width-97-5-per {
  width: 97.5% !important;
}

.height-100 {
  height: 100%;
}

.modal-container {
  background-color: white;
  z-index: 101;
  position: fixed;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  border-radius: 10px;
}

.m-w-50 {
  min-width: 50% !important;
}

.warning-del-number-container {
  width: 600px;
  background-color: white;
  z-index: 101;
  position: fixed;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  border-radius: 10px;
  transition: 500ms all;
}

.end-date-svg {
  position: absolute;
  height: 18px;
  top: 7px;
  right: 8px;
}

.empty-invoices {
  position: absolute;
  top: 50%;
  left: 40%;
  z-index: 1;
  font-weight: 500;
  color: grey;
}

.empty-calls-sms {
  position: absolute;
  top: 70%;
  left: 43%;
  z-index: 1;
  font-weight: 500;
  color: grey;
}

.calls-sms-header-bar {
  background-color: #e0e0e0;
  height: 1px;
  width: 100%;
  z-index: 2;
  position: absolute;
  top: 52px;
}

.calls-sms-dt-header-color {
  background-color: #00000005;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 4rem;
  margin-left: 1px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.calls-sms-dt-filters {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 260px;
  height: 40px;
  position: absolute;
  right: -8px;
  bottom: 7px;
  gap: 5px;
  background-color: white;
}

.modal-empty-calls {
  position: absolute;
  left: 50%;
  bottom: 40%;
  z-index: 2;
  font-weight: 500;
  color: grey;
  transform: translate(-50%, -50%);
}

.coming-soon-3 {
  padding: 3% 0px;
  overflow: hidden;
  border-radius: 10px;
  min-height: max-content;
  background-color: #d7e1ef !important;
}

.scope-countries-container {
  height: 110px;
  overflow-y: scroll;
}

.selected-scope-countries-container {
  max-height: 20rem;
  overflow-y: scroll;
}

.modal-selected-scope-countries-container {
  max-height: 100%;
  overflow-y: scroll;
}

.listing-dt-h {
  min-height: 38rem !important;
}

.invoices-dt-h {
  min-height: 39.5rem !important;
}

.invoices-modal-style {
  bottom: 15px !important;
}

.modal-listing-dt-filter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  position: absolute;
  right: 1rem;
  bottom: 2rem;
  gap: 5px;
  background-color: white;
  width: 19em;
}

.invoices-modal .rdt_Pagination {
  left: 20px;
  position: absolute;
  bottom: 9px;
  width: 97%;
}

.left-summary {
  width: 41%;
  padding-right: 20px;
  margin-right: 20px;
  border-right: 1px solid #ebebeb;
  min-height: 30rem;
}

.pay-order-modal {
  width: 87rem !important;
}

.payment-modal-body {
  height: 43.4em;
}

.payment-modal-body-small {
  height: 35.1em;
}

.payment-tab > tbody > tr > td:first-child {
  background-color: #efefef;
  width: 40%;
  font-weight: 600;
}

.payment-tab > tbody > tr > td {
  padding: 10px;
  border: #d3d3d3 solid 1px;
}

.c-red {
  color: red !important;
}

.tab-title{
background-color: #efefef!important;
font-weight: 600!important;
}

.tab-invsible-cell{
  background-color: transparent!important;
  border: none!important;
}

.modal-notice{
  color: #2c2c2c;
  background-color: #f1f1f11a;
  border: dashed 1px grey;
}



.listing-modal-search-input-dt {
  width: 153px;
  border: solid 1px rgb(224 224 224);
  border-radius: 2px;
  height: 27px;
  color: rgba(0, 0, 0, 0.363);
  padding-left: 5px;
}

.listing-modal-search-input-dt.w-fit-content {
  width: fit-content;
}

.listing-modal-search-input-dt::placeholder {
  color: rgba(0, 0, 0, 0.363);
}

.modal-header-color{
  background-color: #00000005;
    position: absolute;
    z-index: 1;
    width: 97.7%;
    height: 4.3rem;
    margin-left: 1px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}