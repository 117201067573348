.buttons-bil{
    display: flex;
    align-items: center;
    gap: 10px;
}

.header-color{
    background-color: #00000005;
    position: absolute;
    z-index: 1;
    width: 96.7%;
    height: 4.3rem;
    margin-left: 1px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}