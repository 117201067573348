.test {
  display: block;
  position: absolute;
}



.div-height {
  height: 100%;
}

.countries-dropdown {
  background-color: var(--kt-input-solid-bg);
  border-color: var(--kt-input-solid-bg);
  color: var(--kt-input-solid-color);
  transition: color 0.2s ease;
  width: 100%;
  height: 100%;
  padding-left: 5px;
}

.title-flex {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.countries-container {
  height: 35px !important;
}

.enabled-button {
  background-color: #507ab4 !important;
  color: white !important;
  cursor: not-allowed !important;
}

.button-active {
  background-color: #507ab4 !important;
  color: white !important;

}

.swal2-show {
  padding-bottom: 30px !important;
}

/* .swal2-title{
    padding-bottom: 15px!important;
} */

.doc-options{
  height: 50px;
}

.docs-passport-flex{
  display: flex;
  gap: 15px;
}
.width-49{
  width: 49%;
}

.search-country-input{
  position: sticky;
  top: 0;
  left: 0;
  border: solid 1px #d7d7d7;
  border-radius: 4px;
  background-color: #ffffff;
  transition: 300ms all;
  margin: 2px 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 2px 8px 10px;
  font-weight: 600;
  color: rgb(114, 114, 114);
}