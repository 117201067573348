.header_settings {
  border: 0 !important;
  box-shadow: var(--kt-card-box-shadow) !important;
  background-color: var(--kt-card-bg) !important;
  padding: 2rem 2rem 0 2rem !important;

  border-radius: 8px !important;
  margin-top: 20px;
}

.doc-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 450px;
}

.azer {
  width: 1745px;
  margin-left: -70px;
}

.invalid-values {
  border: 1px solid red !important;
}

.svg-error {
  width: 20px;
  position: absolute;
  height: 20px;
  right: 10px;
  top: 50px;
}

.docs-datatablesss {
  margin-bottom: 20px !important;
  background-color: red !important;
}

.custom-datatable {
  background-color: red !important;
  padding: 500px;
}

.clicked-item {
  color: red !important;
}

.update-image {
  position: absolute;
  right: -8px;
  top: -8px;
}


.width-100 {
  width: 100%;
}

.btn-disable {
  background-color: #a34747 !important;
  color: white !important;
  transition: 300ms all !important;
  width: 42em!important;
}

.btn-disable:hover {
  background-color: #a73535!important;
  color: white !important;
}

.width-three {
  width: 58em !important;
}

.coming-soon {
  padding: 8% 0px;
  margin: 20px 0px;
  overflow: hidden;
  border-radius: 10px;
  min-height: max-content;
  background-color: #d7e1ef !important;
}

.margin-top-bottom {
  margin: 20px 0;
}

.avatar-spinner {
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  width: 30px;
  height: 30px;
  border: 4px solid #a9a9a9;
  border-top-color: #2e507f;
  border-radius: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1turn);
  }
}

.avatar-spinner-container {
  height: 100px;
  background-color: #ffffffab;
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
}

.cursor-pointer{
  cursor: pointer;
}

.img-empty-al{
  height: 190px;
}

.no-alerts-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: grey;
  font-weight: 600;
}

.countries-dropdown option {
  height: 50px!important;
  margin-top: 100px!important;

}




.pass-date-svg{
  position: absolute;
  height: 18px;
  top: 7px;
  right: 10px;
}

.pl-35{
  padding-left: 35px!important;
}

.pw-eye-icon{
  position: absolute;
  right: 10px;
  top: 6px;
}