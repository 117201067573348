.flex-inline-container{
    display: flex;
    gap: 10px;
}

.flex-inline span{
    display: flex;
    align-items: center;
}

.w-18px{
    width: 18px;
}

.flex-column-rep{
    display: flex!important ;
    justify-content: space-between!important;
    align-items: center!important;
 
}

.position-flex-rep{
    display: flex;
    align-items: center;
    justify-content: center;
}

.top-caller{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.flag-container{
    width: 45px;
    height: 45px;
    background-color: #f5f8fa;
    display: flex;
    justify-content: center;
align-items: center;
border-radius: 5px;
}

.color-green{
    color: green!important
}



.flex-stats-center{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

