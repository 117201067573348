@media screen and (max-width: 660px) {
  .timeline {
    width: 100%;
    padding: 4em 0 1em 0;
  }

  .timeline li {
    padding: 2em 0;
  }

  .direction-l,
  .direction-r {
    float: none;
    width: 100%;

    text-align: center;
  }

  .flag-wrapper {
    text-align: center;
  }

  .flag {
    background: rgb(255, 255, 255);
    z-index: 15;
  }

  .direction-l .flag:before,
  .direction-r .flag:before {
    position: absolute;
    top: -30px;
    left: 50%;
    content: " ";
    display: block;
    width: 12px;
    height: 12px;
    margin-left: -9px;
    background: #fff;
    border-radius: 10px;
    border: 4px solid rgb(255, 80, 80);
    z-index: 10;
  }

  .direction-l .flag:after,
  .direction-r .flag:after {
    content: "";
    position: absolute;
    left: 50%;
    top: -8px;
    height: 0;
    width: 0;
    margin-left: -8px;
    border: solid transparent;
    border-bottom-color: rgb(255, 255, 255);
    border-width: 8px;
    pointer-events: none;
  }

  .time-wrapper {
    display: block;
    position: relative;
    margin: 4px 0 0 0;
    z-index: 14;
  }

  .direction-l .time-wrapper {
    float: none;
  }

  .direction-r .time-wrapper {
    float: none;
  }

  .desc {
    position: relative;
    margin: 1em 0 0 0;
    padding: 1em;
    background: rgb(245, 245, 245);
    -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);

    z-index: 15;
  }

  .direction-l .desc,
  .direction-r .desc {
    position: relative;
    margin: 1em 1em 0 1em;
    padding: 1em;

    z-index: 15;
  }
}

.dt-filters {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 260px;
  height: 40px;
  position: absolute;
  right: 28px;
  bottom: 33px;
  gap: 5px;
  background-color: white;
}


.listing-dt-filters {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 260px;
  height: 40px;
  position: absolute;
  right: 20px;
  bottom: 35px;
  gap: 5px;
  background-color: white;
  bottom:25px
}

.dt-filter-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.svg-filters {
  width: 10px;
  height: 10px;
}

.datatable-button {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: 500ms all;
  cursor: pointer;
}

.datatable-button:hover {
  background-color: rgba(0, 0, 0, 0.103);
}

.datatable-button-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.datatable-button-disabled:hover {
  background-color: rgb(255, 255, 255);
}



.date-range {
  position: absolute;
  z-index: 1;
  right: 9px;
  top: 53px;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.041);
}

.rdrDay {
  height: 30px !important;
}

.rdrDateDisplayWrapper {
  background-color: white !important;
}

.row-dt {
  display: grid;
  grid-template-columns: 1fr 2fr 3fr 1fr 1fr;
  grid-template-rows: 100px;
  gap: 10px;
}

.skeleton-dt {
  background: #e1e1e142;
  border-radius: 4px;
  height: 50px;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 30px;
}

.skeleton-dt::before {
  content: "";
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(
    to right,
    transparent 0%,
    #e8e8e8 50%,
    transparent 100%
  );
  animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}

.date-listing {
  position: absolute;
  top: 165px;
  right: 68px;
}

.react-dt-header {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
}

.rdt_TableCol{
  padding-right: 0!important ;
}


.listing-search-input-dt {
  width: 155px;
  border: solid 1px rgb(224 224 224);
  border-radius: 2px;
  height: 27px;
  color: rgba(0, 0, 0, 0.363);
  padding-left: 5px;
}

.listing-search-input-dt.w-fit-content {
  width: fit-content;
}

.listing-search-input-dt::placeholder {
  color: rgba(0, 0, 0, 0.363);
}

.dt-header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.sort-by-filter {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding-left: 5px;
  z-index: 2;
}

.svg-filters-up-down {
  width: 16px;
  height: 16px;
  transition: 500ms all;
}

.svg-filters-up-down:hover {
  /* background-color: rgba(0, 0, 0, 0.11);
  border-radius: 50px; */
  cursor: pointer;
}

.react-dt-header span {
  font-size: 12px;
  font-weight: 700;
  color: #aaaaaa;
}

.title-dt {
  width: fit-content;
  padding-top: 17px;
  padding-bottom: 18px;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  color: #919191;
  display: flex;
  align-items: center;
  gap: 12px;
}

.filter-down {
  margin-top: -10px;
}

.header-bar {
  background-color: #e0e0e0;
  height: 1px;
  width: 98%;
  z-index: 2;
  position: absolute;
  top: 71px;
}

.datatable-style {
  z-index: 1;
}

.search-input-dt-id {
  width: 72px !important;
}

.search-input-dt-status {
  width: 75px !important;
}

.search-input-dt-direction {
  width: 15em;
  cursor: pointer;
}

.search-input-dt-date {
  width: 15em;
  cursor: pointer;
}

.empty-calls {
  position: absolute;
  left: 788px;
  bottom: 27px;
  z-index: 2;
}

.time-picker-dt {
  z-index: 100;
}

.clear-button {
  display: inline-block;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  background-color: #e74c3c;
  color: #fff;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.clear-button:hover {
  background-color: #c0392b;
}

.clear-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.date-input {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.clear-button {
  display: inline-block;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  background-color: #e74c3c; /* Red background color */
  color: #fff; /* White text color */
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.clear-button:hover {
  background-color: #c0392b; /* Darker red on hover */
}

.clear-button:disabled {
  background-color: #ccc; /* Grey when disabled */
  cursor: not-allowed;
}

.call-direction {
  background-color: #bbbfd31f;
  color: #606060;
  padding: 4px;
  border-radius: 5px;
  font-size: 0.9rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;
}

.call-direction-icon {
  height: 14px;
}

.badge-light-succeded {
  color: #149525 !important;
  background-color: hwb(125 69% 5% / 0.3);
}

.badge-light-neutral {
  color: #606060 !important;
  background-color: hwb(0deg 0% 100% / 10%) !important;
}

.coming-soon {
  padding: 8% 0px;
  margin: 20px 0px;
  overflow: hidden;
  border-radius: 10px;
  min-height: max-content;
  background-color: #d7e1ef !important;
}

.listing-flag {
  width: 20px;
}

.coming-soon-2 {
  padding: 11% 0px;
  margin: 20px 0px;
  overflow: hidden;
  border-radius: 10px;
  min-height: max-content;
  background-color: #d7e1ef !important;
}

.export-options {
  position: absolute;
  top: 35px;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.export-options div {
  background-color: #e3efff6e !important;
  color: #507ab4 !important;
  height: 30px !important;
  cursor: pointer !important;
  width: 90px;
  border-radius: 5px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  position: relative;
  transition: 500ms all;
}

.export-options div:hover {
  background-color: #e3efff !important;
}

.img-download {
  height: 1.1rem;
}

.height-27 {
  height: 27px;
  width: 166px;
}

.dt-filters-list {
  display: flex;
  align-items: center;
  background-color: #e4ecf7;
  border-radius: 4px;
  padding: 0px 7px;
  font-size: 11px;
  font-weight: 600;
  color: #507ab4;
  height: 25px;
  position: relative;
}

.delete-filter-btn {
  padding: 0px;
  color: white;
  font-size: 10px;
  right: -11px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 500ms all;

  height: 100%;
}

.opacity-1 {
  opacity: 1 !important;
}

.ant-picker-input input {
  width: 0px !important;
  font-size: 10px;
}

.ant-picker-input input::placeholder {
  font-size: 11px !important;
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ant-picker {
  height: 27px;
  width: 55px;
  padding: 0 8px !important;
}

.ant-picker-suffix {
  display: none !important;
}

.ant-picker-clear {
  display: none;
}

.ant-picker-now-btn {
  color: rgb(85, 85, 85) !important;
}

.ant-btn {
  background-color: #507ab4 !important;
  color: white !important;
}

.timePickerFlex .ant-picker:nth-child(1) {
  border-bottom-left-radius: 2px !important;
  border-top-left-radius: 2px !important;

  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  width: 100%;
}

.timePickerFlex .ant-picker:nth-child(2) {
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;

  border-bottom-right-radius: 2px !important;
  border-top-right-radius: 2px !important;
  margin-left: -1px;
  width: 100%;
}

.timePickerFlex {
  display: flex;
  align-items: center;
  gap: 0px;
  width: 155px!important;
}

.ant-picker-input input {
  font-size: 12px !important;
}

.all-filters {
  padding: 8px;
  border-radius: 5px;
  display: flex;
  align-items: flex-start;
  gap: 5px;
  flex-wrap: wrap;
  /* background-color: #f7f7f7; */
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.filters-title {
  font-size: 12px;
}

.clear-all {
  background-color: #52678b;
  color: white;
  font-size: 12px;
  padding: 0px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: 500ms all;
  font-weight: 600;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit-time-picker {
  background-color: #507ab4;
  height: 23px;
  width: 6em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: 500ms all;
  font-size: 10px;
  margin-left: 5px;
}

.submit-time-picker:hover {
  background-color: #4074bd;
}

.search-png {
  width: 13px;
}

.delete-all-icon {
  height: 18px;
}

.select-disabled-option {
  color: grey;
}

.x-close {
  height: 10px;
}

.filter-img {
  height: 15px;
}

.td-filter {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
}

.listing-dt-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: 10px 30px;
}

.first-block {
  display: flex;
  /* background: darkred; */
  justify-content: space-between;
  align-items: center;
  height: 57px !important;
}

.filters-icon {
  width: 9px;
}

.filters-tag {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 63px;
  font-weight: 600;
  color: #323232;
  padding-top: 4px;
}

.filters-container {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  max-width: 95%;
}

.dateTimePickerFlex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dateTimePickerFlex input {
  height: 27px;
  width: 47px;
  padding: 0 4px !important;
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
}

.dateTimePickerFlex input:nth-child(1) {
  border-bottom-left-radius: 2px !important;
  border-top-left-radius: 2px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  height: 27px;
  font-size: 8px;
  /* width: 47px; */
}

.dateTimePickerFlex input:nth-child(2) {
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-right-radius: 2px !important;
  border-top-right-radius: 2px !important;
  margin-left: -1px;
  height: 27px;
  font-size: 8px;

  /* width: 47px; */
}

.dateTimePickerFlex input::placeholder {
  font-size: 10px !important;
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(0, 0, 0, 0.363);
}

.dateTimePickerFlex .flatpickr-input {
  border-bottom-left-radius: 2px !important;
  border-top-left-radius: 2px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  height: 27px;
}

.display-flex-inline {
  display: flex;
  align-items: center;
}

.form-label-export {
  width: 35% !important;
  height: 30px;
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #484848;
}

.doc-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 64%;
}

.height-291 {
  height: 291x !important;
}

.height-223 {
  height: 223px !important;
}

.heigh-2363-imp {
  height: 263px !important;
}

.flex-form-export {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 15px;
  padding-bottom: 5px;
}

.svg-date {
  height: 15px;
  position: absolute;
  right: 5px;
  top: 6px;
}

.flex-export {
  display: flex !important;
  align-items: center;
}

.form-control .date-picker-btn {
  width: 100% !important;
  z-index: 1;
}

.mrg-min {
  margin-left: -1px;
}

.btn-primary-active .date-picker-btn {
  width: 100%;
}

.fezefzef {
  position: absolute;
}

.flex-drawer-export {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  width: 43rem;
}

.export-drawer-form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.export-form {
  width: 92%;
  border: solid 1px #80808017;
  border-radius: 4px;
  background-color: #f9f9fd5c;
}
.pw-drawer-form {
  width: 100%;
}

.container-flat {
  width: 65% !important;

}
.container-flat input:nth-child(1) {
  width: 50% !important;
}

.container-flat input:nth-child(2) {
  width: 50% !important;
}

.height-228 {
  height: 228px !important;
}

.wid-100-per {
  width: 100% !important;
}

.drawer-border {
  box-shadow: 174px -75px 54px 172px rgba(0, 0, 0, 0.6) !important;
  -webkit-box-shadow: 174px -75px 54px 172px rgba(0, 0, 0, 0.6) !important;
  -moz-box-shadow: 174px -75px 54px 172px rgba(0, 0, 0, 0.6) !important;
}
.drawer-back {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.233);
  width: 100%;
  height: 100%;
  z-index: 100 !important;
}

.dt-header-color {
  background-color: #00000005;
  position: absolute;
  z-index: 1;
  width: 97.9%;
  height: 4rem;
  margin-left: 1px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.export-tab {
  margin-left: 5%;
  margin-top: 3%;
}

.drawer-gap {
  gap: 5%;
}

*,
*::after,
*::before {
  box-sizing: border-box;

  margin: 0;
  padding: 0;
}

p {
  line-height: 1.8;
}

time {
  line-height: 1.6;
}

body {
  min-width: 375px;

  font-family: "Poppins", sans-serif;

  background-color: #f1f1f1;
  color: #1a1a1a;
}

/* Timeline */

*,
*::after,
*::before {
  box-sizing: border-box;

  margin: 0;
  padding: 0;
}

time {
  line-height: 1.6;
}

/* ===================== */
/* ===================== */

/* Timeline */
/* Code Here ↓ */

.timeline {
  padding: 3rem 2rem;
  width: 80%;
  max-width: 460px;
  border-radius: 12px;

  background-color: white;
  box-shadow: 0 4px 25px -20px rgba(0, 0, 0.2);
}

.tl-content .tl-header,
.tl-content .tl-body {
  padding-left: 25.6px;
  border-left: 3px solid gainsboro;
}

.tl-body {
  padding-bottom: 1rem;
}

.tl-content:last-child .tl-body {
  border-left: 3px solid transparent;
}

.tl-header {
  position: relative;
  display: grid;

  padding-top: 0rem;
  padding-bottom: 0rem;
}

.tl-title {
  font-weight: 600;
  font-size: 1em;

  border-bottom: 1px solid gainsboro;
}

.tl-time {
  font-size: 0.7em;
}

.tl-marker {
  display: block;
  position: absolute;

  width: 16px;
  height: 16px;
  border-radius: 50% / 50%;

  background: gainsboro;

  left: -1.3rem;
  top: 32%;

  transform: translate(50%, -50%);
}

.tl-content-active .tl-marker {
  padding: 1.6px;

  left: -1.5rem;

  width: 18px;
  height: 18px;

  border: 2px solid limegreen;

  background-color: limegreen;
  background-clip: content-box;

  box-shadow: 0 0 15px -2px limegreen;
}

.tl-content-warning .tl-marker {
  padding: 1.6px;

  left: -1.5rem;

  width: 18px;
  height: 18px;

  border: 2px solid #ffc700;

  background-color: #ffc700;
  background-clip: content-box;

  box-shadow: 0 0 15px -2px #ffc700;
}

.tl-content-danger .tl-marker {
  padding: 1.6px;

  left: -1.5rem;

  width: 18px;
  height: 18px;

  border: 2px solid #f1416c;

  background-color: #f1416c;
  background-clip: content-box;

  box-shadow: 0 0 15px -2px #f1416c;
}

.tl-content-active .tl-title {
  font-weight: 700;

  color: green;
}

.tl-content-danger .tl-title {
  font-weight: 700;

  color: #f1416c;
}

.tl-content-warning .tl-title {
  font-weight: 700;
  color: #ffc700;
}


/* ==================== */

.upload-div-container {
  width: 100%;
  display: flex;
  justify-content: center;
}



.drawer-header-bar {
  background-color: #e9e9e9;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 77px;
  left: 0;
}

.opacity-0{
  opacity: 0!important
}

.hight-priority{
  z-index: 9999;
}

.h-30em{
  height: 30em;
}

.export-filters-container {
  max-width: 65%!important;
}

.sms-list-modal{
width: 92em;
}

.export-filters {
  display: flex;
    align-items: center;
    background-color: #e4ecf7;
    border-radius: 4px;
    padding: 0px 4px;
    font-size: 10px;
    font-weight: 600;
    color: #507ab4;
    height: 25px;
    position: relative;
}

.target-sms{
 background-color: #4839d5!important;
}

.target-sms::before {
  background-color: #4839d5!important;
}

.p-sms-body{
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

.export-svg{
  width: 33px!important;
}

.export-flatpicker{
  font-size: 11px!important;
}