.user-icon {
  height: 31px;
  border-radius: 50%;
}

.ticket-actions {
  display: flex;
  align-items: center;
  width: 110px;
  gap: 1em;
}

.fs-10px {
  font-size: 10px !important;
}

.uploaded-file2 {
  padding: 6px;
  background: #80808012;
  border-radius: 5px;
}

/* .sidebar-container::before {
  content: "";
  position: absolute;
  background-color: rgb(221 202 249);
  width: 100%;
  height: 100%;
  inset-block-start: 0;
  inset-inline-start: 0;
  opacity: 0.05;
} */

.bc-white {
  background-color: white !important;
}

.bc-primary {
  background-color: #837eb1 !important;
}

.bc-secondary {
  background-color: #c061ff !important;
}

.fc-white {
  color: white !important;
}

.doc-container {
  padding: 4px 6px;
  background-color: #f7f7f7;
  border-radius: 6px;
  border: 1px solid #e7e7e7;
}

.doc-container > span {
  font-size: 10px;
  color: #606060;
  font-weight: 600;
}

.right-message::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #837eb1;
  position: absolute;
  transform: rotate(45deg);
  top: -4px;
  right: 10px;
}


.empty-tickets{
  position: absolute;
  left: 788px;
  bottom: 3px;
  z-index: 2;
  font-weight: 500;
  color: #747474;
}