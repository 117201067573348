.spinner-gif{
  position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
width: 30px
}

.preloader{
  display: block;
}
.low-opacity{
  opacity: 0.8!important;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
}



.active-menu-item {
  color: #507ab4 !important;
}


.disabled-event{
  pointer-events: none!important;
  opacity: 0.3!important;
}

.btn-primary-active {
  background-color: #507ab4 !important;
  color: white !important;
}

.h-100{
  height: 100%!important;
}

.display-none{
  display: none!important;
}