.dt-user-numbers {
  display: flex;
  align-items: center;

  padding: 2px 0;
}

.dt-user-all-numbers {
  display: flex;
  flex-direction: column;
  padding: 2px 0;
}

.card-fixed-height {
  min-height: 307px !important;
}

.user-dd-menu {
  position: absolute;
  top: 64px;
  right: 10px;
}



.svg-custom {
  width: 16px !important;
  height: 16px !important;
  color: var(--kt-text-primary);
}

.user-flex {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.user-extension-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.user-card-icon {
  width: 1rem;
}

.user-extension-details div {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
  padding: 5px 10px;
  background-color: var(--kt-light) !important;
  border-radius: 0.475rem !important;
}

.add-number-button {
  background-color: rgb(236, 236, 236);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  padding: 5px;
  transition: 500ms all;
  cursor: pointer;
}
.add-number-button:hover {
  background-color: rgb(206, 206, 206);
}

.add-number-btn {
  width: 20px;
}

.user-card-title {
  font-weight: 600;
  color: rgb(68, 68, 68);
}

.users-actions-btn {
  position: absolute !important;
  right: 132px !important;
  top: 47px !important;
  width: 267px !important;
  font-size: 12px;
}


.event-none{
  pointer-events: none;
  cursor: not-allowed!important;
}

.flex-filters{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
}

.height-date{
  height: 39px;
}

.view-pw-btn{

  height: 5rem;
  margin-right: -11px;
  width: 12%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  background-color: #f5f8fa;
  border-radius: 5px;
  padding: 2px;
  gap: 7px;
  transition: 300ms all;
  cursor: pointer;
  position: absolute;
  right: 11px;

}

.view-pw-btn:hover{
  background-color:#eff2f5;
}

.view-btn{
  background-color: #f5f8fa !important;
  border: 1px solid #e9e9e9 !important;
  color: #7e8299 !important;
  border-radius: 4px;
  cursor: pointer;
  transition: 500ms all;
  display: flex;
  align-items: center;
  gap: 7px;
  justify-content: flex-start;
  padding-left: 3px;
  padding-right: 7px;
  margin-top: -5px;
}

.view-btn:hover{
  background-color:#dce2e6;
}

.svg-show-pw{
  height:20px;
}


.svg-dim{
  height: 20px;
}

.credentials-modal-res{
  padding: 16px 20px;
}

.wid-48-per{
  width: 48%;
}

.flex-space-between{
  justify-content: space-between;
}

.pw-flex{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.flex-col-user{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.padi-20{
  padding-left: 20px;
}
.flex-col-user span{
  color: grey;
    font-weight: 600;
    font-size: 12px;
}

.cred-block {
    height: 6rem!important;
}

.numbers-menu-container{
  width: 100%;
  height: 110px;
  background-color: white;
  position: absolute;
  left: 0;
  top: 41px;
  border: 1px solid #eff2f5;
  border-radius: 4px;
  padding: 5px 2px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.numbers-menu-line{
width: 100%;
height: 30px;
background-color: red;
border-radius: 4px;

}

.top-35{
  top: 35px;
}

.top-41{
  top: 41px;
}
.h-44px{
  height: 44px;
}


.selected-numbers{
  display: flex!important;
  align-items: center!important;
  justify-content: flex-start!important;
  gap: 5px!important;
  background-color: lch(68 37.09 265.66 / 0.04)!important;
  border: 1px solid #507ab417!important;
}

.selected-numbers > span{
  background-color: #e3efff;
  padding: 3px 5px;
  color: #507ab4;
  border-radius: 4px;
  font-weight: 500;
  font-size: 13px;
  display: flex;
  align-items: center;
  border: solid #507ab438 1px;
  gap: 5px;
}



.selected-numbers span img{
  height: 11px;
  border-radius: 4px;
}


.number-selected{
  background-color: #d5e7ff !important;
}


.number-flag{
  height:13px;
  width: 15px;
  border-radius: 4px;
  object-fit: cover;
}

.menu-flag-drop-down {
  position: absolute;
  width: 100%;
  max-height: 170px;
  background-color: white;
  z-index: 1;
  border-radius: 5px;
  border: 1px solid #eff2f5;
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow-y: scroll;
}


.menu-flag-drop-down > div {
  min-height:40px;
  width: 100%;
  background-color: #f9fdff;
  border-radius: 5px;
  transition: 300ms;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding-left: 10px;
}

.menu-flag-drop-down > div:hover {
  background-color: #e8f7ff;
}