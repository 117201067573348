.b-more {
  background-color: #fbfbfb;
  border-radius: 4px;
  color: #2e507f;
}

.conversation-container {
  transition: 300ms all;
  border-radius: 4px;
}

.conversation-container:hover {
  background-color: #f1faff;
}

.active-conversation {
  background-color: #f1faff;
}

.cal-container {
  background-color: #37136f0f;
  width: 37px;
  border-radius: 0.475rem;
}

.lh-0 {
  line-height: 0 !important;
}

.fs-1em {
  font-size: 1em !important;
}

.sms-search-svg {
  margin-top: -0.4em !important;
}
.p-07 {
  padding: 0.775rem !important;
}

.numbers-select {
  height: 2.5rem;
  color: var(--kt-input-solid-color);
  background-color: #e7ecf7;
  transition: color 0.2s ease;
  border: 1px solid #5345db3b;
  border-radius: 4px;
  cursor: pointer;
  width: 16em;
}

.numbers-select > span {
  position: absolute;
  top: 5.9px;
  left: 11px;
  font-weight: 500;
  color: #727272;
}

.numbers-select > div {
  min-height: 40px;
  width: 16em;
  position: absolute;
  /* bottom: 33px; */
  /* left: -150px; */
  border-radius: 4px;
  border: solid #eeecff 1px;
  display: flex;
  flex-direction: column;
  background-color: #f1f4f5;
  z-index: 1;
  cursor: pointer !important;
  top: 32px;
}

.numbers-select > div > span {
  background-color: #e7ecf7;
  transition: 300ms all;
  margin: 2px 4px;
  height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  font-weight: 600;
  color: rgb(114, 114, 114);
}

/* .currency-div > div > label {
  background-color: #f8f7ff;
  transition: 300ms all;
  margin: 2px 4px;
  height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  font-weight: 600;
  color: rgb(114, 114, 114);
} */

.numbers-select > div > label {
  height: 1px !important;
  background-color: #cdc9f7 !important;
  margin: 3px 6px !important;
}

.numbers-select > div > span:hover {
  background: #5472ae !important;
  color: white !important;
}

.selected-number {
  background: #5472ae !important;
  color: white !important;
}

.bg-theme-light {
  background-color: #fbfbfd !important;
}

.react-tel-input .flag-dropdown {
  top: 1px !important;
  bottom: 1px !important;
  border-radius: 5px 0 0 5px !important;
  left: 1px !important;
}

.invalid-value {
  border: 1px solid red !important;
}

/* .sms-right::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #837eb1;
  position: absolute;
  transform: rotate(45deg);
  top: -4px;
  right: 10px;
} */

.sms-right {
  background-color: #f9f9fd;
  color: #3a3a3a;
  border-top-right-radius: 0!important;
}



/* .sms-left::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #c061ff;
  position: absolute;
  transform: rotate(45deg);
  top: -4px;
  left: 10px;
} */

.sms-left {
  background-color: #f9f9fd;
  color: #3a3a3a;
  border-top-left-radius: 0!important;
}

.sms-rounded{
  border-radius: 0.475rem;
}

.number-container {
  padding: 4px 10px;
  background-color: #fbfbfb38;
  border: #f7f7f7 1px solid;
  border-radius: 4px;
}

.min-dim {
  min-width: 30px;
  min-height: 30px;
}

.pr-10x {
  padding-right: 10px !important;
}
.sms-indicators {
  top: -27px;
  left: 12px;
}

.sms-text-zone {
  padding: 15px !important;
  margin-top: 20px !important;
}

.sms-indicators > span {
  font-size: 11px;
  background-color: #efefef;
  padding: 2px 4px;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  font-weight: 600;
  color: #616161;
}

.emoji-container {
  position: absolute !important;
  bottom: 30px;
  right: 0;
}

.sms-modal-indicators {
  position: absolute;
  left: 0;
  background-color: #f3f1f7 !important;
  width: 96.6%;
  bottom: -40px;
  height: 3em;
  align-items: center;
  justify-content: flex-start !important;
  border: 1px solid #37136f1a;
  padding: 0 7px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.sms-modal-indicators-container {
  background-color: #f3f1f7;
  position: absolute;
  width: 100%;
  bottom: 2px;
}

.sms-modal-indicators > div > span {
  font-size: 11px;
  background-color: #ded9ff;
  padding: 2px 4px;
  border-radius: 4px;
  border: 1px solid #bfbbdf;
  font-weight: 600;
  color: #7169c1;
}

.pb-25px {
  padding-bottom: 25px !important;
}

.focus-off:focus {
  background-color: #37136f0f !important;
}

.b-bottom-radius-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.mh-10em {
  min-height: 10em !important;
}

.badge-used-number {
  padding: 2px 8px;
  background-color: #fbfbfb38;
  border: #f7f7f7 1px solid;
  border-radius: 4px;
}

.mw-90pc{
  max-width: 90%!important;
}